import { addStakeApi, addUserUnStakeApi, getStakeApi } from "../constant/api";
import * as opsService from "./Ops";


export const addStake = async (data) => {
  const token =localStorage.getItem("token")
  let result = await opsService.postdata(addStakeApi, data,token);
  return result;
};

export const addUserUnStake = async (data) => {
  const token =localStorage.getItem("token")
  let result = await opsService.postdata(addUserUnStakeApi, data,token);
  return result;
};

export const getStake = async () => {
  const token =localStorage.getItem("token")
  let result = await opsService.getData(getStakeApi,token);
  return result;
};