import { getAdminReferralApi, userDetailApi, userLoginApi, userRegisterApi } from "../constant/api";
import * as opsService from "./Ops";
const token =localStorage.getItem("token")

export const userLogin = async (data) => {
  let result = await opsService.postdata(userLoginApi, data);
  return result;
};

export const userRegister = async (data) => {
  let result = await opsService.postdata(userRegisterApi, data);
  return result;
};

export const userDetail = async () => {
  const token =localStorage.getItem("token")
  let result = await opsService.getData(userDetailApi,token);
  return result;
};

export const getAdminReferral = async () => {
  let result = await opsService.getData(getAdminReferralApi);
  return result;
};
