import React,{useState,useEffect} from "react";
import Header from "../widgets/Header";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { bnbChainId, bnbRpcUrl,projectId } from "../constant/enum";
import { toast } from "react-toastify";
import { getAdminReferral, userRegister } from "../services/Login";

function Register() {
  const {referralCode}=useParams()
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const navigate=useNavigate()
  const {authenticated, login,setWallet_address}=useAuth()
  const [walletAddress, setWalletAddress] = useState("")
  const [referral, setReferral] = useState("")
  const [disable, setDisable] = useState(false)
  const [adminReferral, setAdminReferral] = useState("")

  useEffect(() => {
      if(referralCode){
        setReferral(referralCode)
      }
  }, [referralCode])

  useEffect(() => {
    getadminReferralCode()
  }, [])
  

  const getadminReferralCode=async()=>{
    const result = await getAdminReferral();
    if (result.status) {
      setAdminReferral(result.data[0]?.referral_code);
    } else {
      setAdminReferral("");
    }
  }
  

  useEffect(() => {
    if(authenticated){
      navigate("/")
    }
  }, [])
  

  useEffect(() => {
     if(address && isConnected){
      setWalletAddress(address)
     }
     else{
      setWalletAddress("")
     }
  }, [address,isConnected])


  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };


  const modal=createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });
  useEffect(() => {
    if(!isConnected && !walletAddress){
      modal.open()
    }
  }, [isConnected])
  

  const handleChange=(e)=>{
    setReferral(e.target.value)
  }

  const handleSubmit=async(e)=>{
    e.preventDefault()
    if(!referral && !walletAddress){
      toast.dismiss();
      toast.error("Required all fields");
      return
    }
    setDisable(true)
    let registerData = {
      wallet_address: walletAddress,
      referral: referral,
    };
    let result = await userRegister(registerData);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("token", token);
      login();
      toast.success(result.message);
      setWallet_address(address)
      localStorage.setItem("wallet_address",walletAddress)
      setTimeout(function () {
        navigate("/dashboard", { replace: true });
      }, 2000);
      setDisable(false)
      return false;
    } else {
      setDisable(false)
      toast.dismiss();
      toast.error(result.message);
      return;
    }
  }

  return (
    <div className="">
                {/* <Header /> */}
                <div className="login-form d-flex align-items-center">
                  <div className="container">
                  <div className="login-form-title text-center">
                    <a href="index.html">
                    <figure className="login-page-logo">
                      <img src="assets/images/login-page-logo.png" alt=""/>
                    </figure>
                    </a>
                    <h2>Create your account.</h2>
                </div>
         
            <div className="login-form-box">
              <div className="login-card"> 
                  <div className="wallet-register">
                    <h3>Register</h3>
                   
                      <form>
                        <div className="form-group">
                          <label className="form-label">Wallet Address</label>
                          <input
                            className="form-control"
                            name="walletAddreses"
                            value={walletAddress}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Referral Code</label>
                          <input
                            className="form-control"
                            name="referralCode"
                            value={referral}
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                    
                      <button className="btn btn-primary" onClick={handleSubmit}>Register</button>
                      <p className="d-flex align-items-center mb-3">Don't have referral code <button className="btn btn-primary btn_small ml-auto mb-0" onClick={()=>{
                        setReferral(adminReferral)
                      }}>Click here</button></p>
                      <p>Already have an account <Link to="/login" className="forgot-password">Login</Link></p>
                    </div> 
                </div>
              </div>
            </div>
          </div> 
    </div>
  );
}

export default Register;
