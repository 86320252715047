import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Header from "../../widgets/Header";
import Footer from "../../widgets/Footer";

function Home() {
    const {authenticated}=useAuth()
  return (
    <div>
       <Header/>
  
 <section className="bannermain position-relative">
    <figure className="mb-0 bgshape">
      <img
        src="./assets/images/homebanner-bgshape.png"
        alt=""
        className="img-fluid"
      />
    </figure>
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
          <div className="banner" data-aos="fade-right">
            
            <h1>
            Maximize<br/> Your Profits with<br/>
             <span>Pay Earn </span>  
            </h1>
            <p className="banner-text">
            Unlock the potential of your investments with our cutting-edge AI technology. Our platform ensures strategic trading for higher returns and making your financial growth our top priority.


            </p>
            <div className="button">
            {authenticated?<Link to="/dashboard" className="btn button_text ">Dashboard</Link>:
                      <>
                      <Link to="/login" className="button_text">
                        Login
                      </Link>
                      <Link to="/register" className="button_text ml-3">
                        Register
                      </Link>
                      </>}


              
            </div>
          </div>
        </div>
        <div className=" col-lg-7 col-md-7 col-sm-12">
          <div className="banner-wrapper">
            <figure className="mb-0 homeelement1">
              <img
                src="./assets/images/homeelement1.png"
                className="img-fluid"
                alt=""
              />
            </figure>
            <figure className="mb-0 banner-image">
              <img
                src="./assets/images/services-our-services-image.png"
                className="img-fluid"
                alt="banner-image"
              />
            </figure>
            <figure className="mb-0 content img-bg">
              <img
                src="./assets/images/homebanner-img-bg.png"
                alt="banner-image-bg"
              />
            </figure>
            <figure className="mb-0 homeelement">
              <img
                src="./assets/images/homeelement.png"
                className="img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*What-we-do*/}
  <section className="about_new position-relative text-center">
    <div className="container">
    
      <div className="row">
        <div className="col-12">
          <div className="subheading" data-aos="fade-right">
            <h6>Pay Earn</h6>
            <h2 className="mb-2">Maximize Your Profits with Pay Earn</h2>
      <p>Pay Earn is a cutting-edge platform that harnesses the power of artificial intelligence for crypto trading. As you know that financial markets are full of risk and you want to start your cryptocurrency journey and you don’t know how and from where to start? In this situation our platform is a best choice for you because as your guide we can manage your portfolio in the best way which you bear less risk and get more profits. </p>
      <p>Because our advanced AI algorithms analyze market trends in realtime and identifying the most profitable trading opportunities to maximize your returns. This ensures that your investments are managed with precision and efficiency.</p>
          </div>
        </div>
      </div>
   
      
    </div>
  </section>
  <section className="what-we-do position-relative">
    <div className="container">
      <figure className="element1 mb-0">
        <img
          src="./assets/images/what-we-do-icon-1.png"
          className="img-fluid"
          alt=""
        />
      </figure>
      <div className="row">
        <div className="col-12">
          <div className="subheading" data-aos="fade-right">
            <h6>What we do</h6>
            <h2>Get Ready To Have Best Smart Payments in The World</h2>
          </div>
        </div>
      </div>
      <div className="row position-relative">
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1">
            <figure className="img">
              <img
                src="./assets/images/security.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Stake</h3>
            <p className="mb-0 text-size-18">
            Stake usdt for the potenitial growth 
            of your money.
            </p>
          </div>
        </div>
        <figure className="arrow1 mb-0" data-aos="fade-down">
          <img
            src="./assets/images/what-we-do-arrow-1.png"
            className="img-fluid"
            alt=""
          />
        </figure>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1 service2">
            <figure className="img">
              <img
                src="./assets/images/salary.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Earn</h3>
            <p className="mb-0 text-size-18">
            Earn Daily income on your staking for maiximum 
            time period.
            </p>
          </div>
        </div>
        <figure className="arrow2 mb-0" data-aos="fade-up">
          <img
            src="./assets/images/what-we-do-arrow-2.png"
            className="img-fluid"
            alt=""
          />
        </figure>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1">
            <figure className="img">
              <img
                src="./assets/images/refer.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Refer and Earn            </h3>
            <p className="mb-0 text-size-18">
            While earning daily income , earn extra 
            income by referring your friend.
            </p>
          </div>
        </div>
        <figure className="element3 mb-0">
          <img src="./assets/images/what-we-do-element.png" alt="" />
        </figure>
      </div>
      <div className="row"> 
        <div className="col-md-4 col-sm-12 col-12">
          <div className="account" data-aos="fade-right">
            <div className="accounticon">
              <figure className="mb-0">
                <img
                  src="./assets/images/metamask.png"
                  className="img-fluid"
                  alt=""
                />
              </figure>
            </div>
            <div className="heading">
              <h3 className="mb-0">Metamask</h3>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 col-12">
          <div className="account" data-aos="fade-right">
            <div className="accounticon">
              <figure className="mb-0">
                <img
                  src="./assets/images/trust_wallet.png"
                  className="img-fluid"
                  alt=""
                />
              </figure>
            </div>
            <div className="heading">
              <h3 className="mb-0">Trust Wallet</h3>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 col-12">
          <div className="account" data-aos="fade-right">
            <div className="accounticon">
              <figure className="mb-0">
                <img
                  src="./assets/images/safepal.png"
                  className="img-fluid"
                  alt=""
                />
              </figure>
            </div>
            <div className="heading">
              <h3 className="mb-0">SafePal</h3>
            </div>
          </div>
        </div>
        
        <figure className="element2 mb-0">
          <img
            src="./assets/images/what-we-do-icon-2.png"
            className="img-fluid"
            alt=""
          />
        </figure>
      </div>
    </div>
  </section>
  {/*About PayEarn*/}
  <section className="about-repay">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="about-wrapper">
            <figure className="circle mb-0">
              <img src="./assets/images/image-2-bg.png" alt="" />
            </figure>
            <div className="position-relative">
              <a
                className="popup-vimeo"
                href="/assets/video.mp4"
              >
                <figure className="mb-0 videobutton">
                  <img
                    className="thumb img-fluid"
                    style={{ cursor: "pointer" }}
                    src="./assets/images/play-button.png"
                    alt=""
                  />
                </figure>
              </a>
            </div>
            <figure className="image mb-0">
              <img
                src="./assets/images/image-2.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="homeelement mb-0">
              <img
                src="./assets/images/homeelement.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="homeelement1 mb-0">
              <img
                src="./assets/images/homeelement.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="about-content" data-aos="fade-up">
            <h6>Cryptocurrency MLM Software
            </h6>
            <h2>Unlock Your Direct Selling Potential With Decentralized Cryptocurrency MLM Website</h2>
            <p className="text-size-18">
            Cryptocurrency MLM Website is a platform designed to manage multilevel marketing businesses using cryptocurrencies. It offers user management, including registration and genealogy tracking, integrates cryptocurrency wallets for secure transactions and payment gateway integration for seamless and instant cryptocurrency transactions.


            </p>
            <p className="text-size-18 text">
            It means this software leverages blockchain technology to ensure transparency, security, and trust within the MLM business model and making it an innovative solution for modern MLM enterprises.


            </p>
          
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Services section*/}
  <section className="service-section">
    <div className="container">
      <div className=" position-relative">
        <div className="service-content row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <figure className="mb-0 services-icon">
              <img
                src="./assets/images/services-our-services-icon-1.png"
                className="img-fluid"
                alt=""
              />
            </figure>
            <h6 className="mb-0">Features </h6>
            <h2>System Features</h2>
            <p>Pay Earn features an advanced AI trading engine that analyzes market conditions and executes trades with precision. Users benefit from customizable strategies, real-time alerts, and a user-friendly dashboard for tracking performance. Robust security, including encryption and two-factor authentication, ensures data and asset safety.

</p>
          </div>
        </div>
      </div>
      <figure className="element1 mb-0">
        <img
          src="./assets/images/what-we-do-icon-1.png"
          className="img-fluid"
          alt=""
        />
      </figure>
      <div className="services-data">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img1">
                <img
                  src="./assets/images/services-payment-management-icon.png"
                  alt=""
                  className="img-fluid "
                />
              </figure>
              <div className="content">
                <h3>Blockchain Integration
                </h3>
                <p className="text-size-18">
                Utilizes blockchain technology to ensure transparency, immutability, and security of transactions and data.  </p>
                
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img2">
                <img
                  src="./assets/images/services-dashboard-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="content">
                <h3>Smart Contracts                </h3>
                <p className="text-size-18">
                Implements smart contracts to automate MLM processes, including rewards distribution, commission payouts, and network growth.


                </p>
                 
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img3">
                <img
                  src="./assets/images/services-integrated-payment-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="content">
                <h3>Decentralization</h3>
                <p className="text-size-18">
                Operates on a decentralized network, removing the need for intermediaries and enabling peer to-peer transactions.


                </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img4">
                <img
                  src="./assets/images/services-friendly.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="content">
                <h3>Peer-to-Peer Transactions
                </h3>
                <p className="text-size-18">
                Facilitates direct transactions between participants without the need for a central authority, allowing for faster and cheaper transaction


                </p>
                
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img4">
                <img
                  src="./assets/images/services-friendly2.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="content">
                <h3>Immutable Recordkeeping
                </h3>
                <p className="text-size-18">
                Ensures that all transactions and data recorded on the blockchain cannot be altered or tampered with, providing trust and reliability.


                </p>
                
              </div>
            </div>
          </div>
         
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="service-box">
              <figure className="img img5">
                <img
                  src="./assets/images/services-credit-debit-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="content">
                <h3>Reward System
                </h3>
                <p className="text-size-18">
                Implements a reward system that fairly compensates participants for their contributions to the network, typically based on sales, referrals, or other predefined actions.


                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <figure className="element2 mb-0">
        <img
          src="./assets/images/what-we-do-icon-2.png"
          className="img-fluid"
          alt=""
        />
      </figure>
    </div>
  </section>
  {/* manage */}
  <section className="manage-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="manage-content" data-aos="fade-right">
            <h2>Smart Contract Deployed on Binance Smart Chain            </h2>
         
           
            <p>Binance Smart Chain is a high-performance blockchain network that is compatible with the Ethereum Virtual Machine and supports smart contracts. It provides fast transaction speeds, low transaction fees and secure transactions, making it a popular choice for Decentralize Apps and Smart contracts that require high-speed and low-cost transactions.

</p>
<p>Binance Smart Chain has become a popular platform for Decentralize Finance projects, offering a wide range of financial services without intermediaries. So, users can create a wallet that is compatible with the network, such as Trust Wallet or MetaMask, and start interacting with Decentralize software on Pay Earn Accounts. They can also use the Binance Smart Chain explorer to view and track transactions on the network.

</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="manage-wrapper">
            <figure className="mb-0 homeelement1">
              <img
                src="./assets/images/homeelement1.png"
                className="img-fluid"
                alt=""
              />
            </figure>
            <figure className="mb-0 manage-image">
              <img
                src="./assets/images/manage-your-everything-image.png"
                className="img-fluid"
                alt=""
              />
            </figure>
            <figure className="mb-0 content img-bg">
              <img
                src="./assets/images/manageyour-mange-your-bg2.png"
                alt=""
                className="raa"
              />
            </figure>
            <figure className="mb-0 homeelement">
              <img
                src="./assets/images/homeelement.png"
                className="img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <figure className="mb-0 manage-layer">
      <img src="./assets/images/mange-layer.png" alt="" className="img-fluid" />
    </figure>
  </section>
  {/* plan and pricing */}
  {/* <section className="plan">
    <div className="container">
      <figure className="element1 mb-0">
        <img
          src="./assets/images/what-we-do-icon-1.png"
          className="img-fluid"
          alt=""
        />
      </figure>
      <div className="row position-relative">
        <div className="col-12">
          <div className="content" data-aos="fade-right">
            <h6>PLAN AND PRICING</h6>
            <h2>Helping You Make Smart Financial Choices</h2>
            <figure className="element3 mb-0">
              <img
                src="./assets/images/what-we-do-element.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="pricing">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h3>Bank Transfer</h3>
                <p className="mb-0 text1 text">*Fees (excl VAT)</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="online-payment">Online Payment</h4>
                <p className="mb-0 text1 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="payout">Payout</h4>
                <p className="mb-0 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <figure className="mb-0 icon">
                  <img
                    src="./assets/images/price-payment-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="pricing">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h3>e-Wallet</h3>
                <p className="mb-0 text1 text">*Need Verification </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="online-payment">Online Payment</h4>
                <p className="mb-0 text1 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="payout">Payout</h4>
                <p className="mb-0 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <figure className="mb-0 icon">
                  <img
                    src="./assets/images/price-payment-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="pricing">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h3>Credit Card</h3>
                <p className="mb-0 text1 text">*Fees (excl VAT)</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="online-payment">Online Payment</h4>
                <p className="mb-0 text1 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="payout">Payout</h4>
                <p className="mb-0 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <figure className="mb-0 icon">
                  <img
                    src="./assets/images/price-payment-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="pricing box">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h3>Cardless Credit</h3>
                <p className="mb-0 text1 text">*Account Must be registered</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="online-payment">Online Payment</h4>
                <p className="mb-0 text1 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <h4 className="payout">Payout</h4>
                <p className="mb-0 text">3.5% plus USD 1.00</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <figure className="mb-0 icon">
                  <img
                    src="./assets/images/price-payment-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row position-relative">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="facility" data-aos="fade-up">
            <ul className="mb-0 list-unstyled">
              <li className="text1">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">Free Account</p>
              </li>
              <li className="text2">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">No Monthly Cost</p>
              </li>
              <li className="text3">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">No Fee Setup</p>
              </li>
              <li className="text4">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">Easy to Setup</p>
              </li>
              <li className="text5">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">Integration</p>
              </li>
              <li className="text6">
                <i className="fa-regular fa-circle-check font1" />
                <p className="mb-0 text-size-16">Custom price</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <figure className="element2 mb-0">
        <img
          src="./assets/images/what-we-do-icon-2.png"
          className="img-fluid"
          alt=""
        />
      </figure>
    </div>
  </section> */}
  {/* need more help? */}
  <section className="need-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="content" data-aos="fade-right">
            <h6>NEED MORE HELP?</h6>
            <h2>Leading, Trusted. Enabling growth.</h2>
            <p></p>
          </div>
        </div>
      </div>
      <div className="row position-relative">
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1">
            <figure className="img img1">
              <img
                src="./assets/images/resume.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Register</h3>
            <p className="text-size-18">
            Easy to Register 
            </p>
            
          </div>
        </div>
        <figure className="arrow1 mb-0" data-aos="fade-down">
          <img
            src="./assets/images/need-arrow1.png"
            className="img-fluid"
            alt=""
          />
        </figure>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1 service2">
            <figure className="img img2">
              <img
                src="./assets/images/funds.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Invest </h3>
            <p className="text-size-18">
            Invest directly from your crypto wallet.
            </p>
           
          </div>
        </div>
        <figure className="arrow2 mb-0" data-aos="fade-up">
          <img
            src="./assets/images/need-arrow-2.png"
            className="img-fluid"
            alt=""
          />
        </figure>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="service1">
            <figure className="img img3">
              <img
                src="./assets/images/refer.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <h3>Refer</h3>
            <p className="text-size-18">
            To earn extra income refer to your friends

            </p>
            
          </div>
        </div>
      </div>
    </div>
    <figure className="mb-0 need-layer">
      <img src="./assets/images/need-layer.png" alt="" className="img-fluid" />
    </figure>
  </section>
                  <Footer/>
                </div>
  );
}

export default Home;
