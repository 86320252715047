import React from 'react'

export default function Footer() {
  return (
    <>
  <section className="footer-section text-center">
    
    <div className="container">
      <div className="middle-portion">
        
            <a href="./index.html">
              <figure className="footer-logo">
                <img
                  src="./assets/images/banner-logo.png"
                  className="img-fluid"
                  alt=""
                />
              </figure>
            </a>
            <p className="text-size-16 footer-text">
            Because our advanced AI algorithms analyze market trends in realtime and identifying the most profitable trading opportunities to maximize your returns. This ensures that your investments are managed with precision and efficiency.

            </p>
            {/* <div className="social-icons mt-3">
                  <div className="circle">
                    <a href="https://www.facebook.com/people/Bit-Pay-Earn/61565745210879/" target="_blank">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </div>
                  <div className="circle">
                    <a href="https://x.com/bitpayearn" target="_blank">
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </div>
                  <div className="circle">
                    <a href="https://www.linkedin.com/company/bitpayearn/" target="_blank">
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </div>
                  <div className="circle">
                    <a href="https://www.instagram.com/bitpayearn/" target="_blank">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </div>
                </div> */}

<img src="./assets/images/coins.png"  alt="coins" className="img-fluid mt-3"/>
          </div>
        
            
    </div>
  </section>
  {/*footer area*/}
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <p className="text-size-16">
            Copyright @2024 PayEarn. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</>

  )
}
