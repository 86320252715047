import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../../AuthContext";
import { bnbChainId, bnbRpcUrl,projectId } from "../constant/enum";

function Header() {
  const {authenticated,wallet_address,logout}=useAuth()
  const { address,isConnected} =  useWeb3ModalAccount();
  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          window.location.href = "/login";
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href = "/login";
        }
      };
      const timeout = setTimeout(checkConnection, 4000); 
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address, logout]);


  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };


  const modal=createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const disconnect=()=>{
    if(isConnected){
      modal.open()
    }
  }
  
  
  return (
    <> 
  {/* <div className="topbar">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 sol-sm-12">
          <div className="email">
            <figure className="mb-0 emailicon">
              <img
                src="./assets/images/header-emailicon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <a
              href="mailto:support@PayEarn.com"
              className="mb-0 text-size-16 text-white"
            >
              support@PayEarn.com
            </a>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 sol-sm-12 d-md-block d-none">
          <div className="mb-0 social-icons">
            <ul className="mb-0 list-unstyled">
              <li>Follow us on:</li>
              <li>
              <a href="https://www.facebook.com/people/Bit-Pay-Earn/61565745210879/" target="_blank">
                  <i className="fa-brands fa-facebook-f" />
                </a>
              </li>
              <li>
              <a href="https://x.com/bitpayearn" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
              <a href="https://www.linkedin.com/company/bitpayearn/" target="_blank">
                  <i className="fa-brands fa-pinterest-p" />
                </a>
              </li>
              <li>
              <a href="https://www.instagram.com/bitpayearn/" target="_blank">
                  <i className="fa-brands fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/*Header*/}
  <header className="header">
    <div className="container">
      <nav className="navbar position-relative navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <figure className="mb-0">
            <img
              src="./assets/images/banner-logo.png"
              alt=""
              className="img-fluid"
            />
          </figure>
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
          <span className="navbar-toggler-icon" />
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {authenticated ? <ul className="navbar-nav ml-auto">
         
            <li className={`nav-item ${window.location.pathname==="/dashboard"?"active":""}`}>
              <Link className="nav-link" to="/dashboard">
                Dashboard
              </Link>
            </li>
            <li className={`nav-item ${window.location.pathname==="/stake-record"?"active":""}`}>
              <Link className="nav-link" to="/stake-record">
                Stake History
              </Link>
            </li>
            <li className={`nav-item ${window.location.pathname==="/roi-record"?"active":""}`}>
              <Link className="nav-link" to="/roi-record">
                Roi
              </Link>
            </li>
            <li className={`nav-item ${window.location.pathname==="/referral-record"?"active":""}`}>
              <Link className="nav-link" to="/referral-record">
                Referral
              </Link>
            </li>
            <li className={`nav-item ${window.location.pathname==="/withdraw-record"?"active":""}`}>
              <Link className="nav-link" to="/withdraw-record">
                Withdraw
              </Link>
            </li>
           
         
            <li className="nav-item">
            {authenticated ?<button
          className="nav-link signup"
          onClick={disconnect}
        >
          {`${wallet_address.substring(0, 7)}...${wallet_address.substring(wallet_address.length - 7)}`}
          
        </button>: ""}  
            </li>
          </ul>:<div className="ml-auto">
          
              <Link className="btn top_btn" to="/login">
                Login
              </Link>
           
              <Link className="btn top_btn ml-4" to="/register">
                Register
              </Link>
            
            </div>
            }
        </div>
      </nav>
    </div>
  </header> 

    
     
    </>
  );
}

export default Header;
