import { getReferralReportApi, getRoiReportApi, getWithDrawReportApi } from "../constant/api";
import * as opsService from "./Ops";


export const getRoiReport = async () => {
  const token =localStorage.getItem("token")
  let result = await opsService.getData(getRoiReportApi,token);
  return result;
};

export const getReferralReport = async () => {
  const token =localStorage.getItem("token")
    let result = await opsService.getData(getReferralReportApi,token);
    return result;
  };

  export const getWithdrawReport = async () => {
    const token =localStorage.getItem("token")
    let result = await opsService.getData(getWithDrawReportApi,token);
    return result;
  };