import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addWithdrawRequest } from "../services/withdraw";
import { toast } from "react-toastify";

export default function WithdrawRequestModal(props) {
  const [amount, setAmount] = useState(0);
  const [amountErr, setAmountErr] = useState("");
  const [availableBalance, setAvailableBalance] = useState(0);
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (props.available_balance) {
      setAvailableBalance(props.available_balance);
    }
  }, [props]);

  const handleClose = () => {
    props.setShowModal(false);
    setAmount(0);
    setAmountErr("");
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAmount(value);
    if (value === "") {
      setAmountErr("This field is required");
    } else if (parseFloat(value) > availableBalance) {
      setAmountErr("Please enter your available balance");
    } else {
      setAmountErr("");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (amount == "") {
      setAmountErr("This field is required");
      return;
    }
    if (amount <= 0) {
      setAmountErr("Please enter valid amount");
      return;
    }
    if (parseFloat(amount) > availableBalance) {
      setAmountErr("Please enter your available balance");
      return;
    }
    setDisable(true);
    const data = {
      amount: amount,
    };
    let result = await addWithdrawRequest(data);
    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      setDisable(false);
       props.getDashboard()
      return false;
    } else {
      setDisable(false);
      toast.dismiss();
      toast.error(result.message);
      return;
    }
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Amount </Form.Label>
              <Form.Control
                name="amount"
                onChange={handleChange}
                value={amount}
                onKeyPress={(event) => {
                  const inputValue = event.target.value;

                  // Allow only numbers and one decimal point
                  if (!/[0-9]/.test(event.key) && event.key !== ".") {
                    event.preventDefault();
                  }

                  // Prevent entering more than one decimal point
                  if (event.key === "." && inputValue.includes(".")) {
                    event.preventDefault();
                  }
                }}
              ></Form.Control>
              <span style={{ color: "red" }}>{amountErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
          <Button className="btn btn_d" variant="btn_d" onClick={onSubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
