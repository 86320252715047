import React,{useState} from 'react'
import {addUserUnStake } from '../../services/stake'
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { stakeStatus } from '../../constant/enum';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from 'react-toastify';

function StakeRecord({stakeData,getStakeData,getDashboard}) {
   const [disable, setdisable] = useState(false)
   

   const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    
    {
      key: "trans_hash",
      text: "Hash",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount (USDT)",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "roi_amount",
      text: "Total Interest (USDT)",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "unstake_amount",
      text: "Unstake Amount (USDT)",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.start_date;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "end_date",
      text: "End Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.end_date;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "duration_days",
      text: "Duration (Days)",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        const status= record.status;
        return <span>{stakeStatus[status]}</span>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary ms-3"
              disabled={record.status=='2' || record.roi_count>25?true:disable}
              onClick={() => {
                unstakeAlert(record.id);
              }}
            >
              <span>Unstake</span>
            </button>
          </>
        );
      },
    },
  
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const unstakeAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => unstakeFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const unstakeFun=async(id)=>{
    const data={id}
    const result=await addUserUnStake(data)
    setdisable(true)
    if (result.status) {
      getStakeData()
      getDashboard()
      toast.dismiss()
      toast.success(result.message);
      setdisable(false)
    }
    else{
      toast.dismiss()
      toast.error(result.message);
      setdisable(false)
    }
  }

  return (
    <div className="">
               <div className="d-flex align-items-center mb-3">
               <h4 className='text-dark mb-0 mr-auto'>Stake List</h4>
               <div className="">
                  <img className="coins h40" src="./assets/images/coins.png"  alt="" />
                  </div>
                  </div>
             
               <ReactDatatable
                config={config}
                records={stakeData}
                columns={columns}
              />
           
  </div>
  )
}

export default StakeRecord