import { baseUrl } from "./BaseUrl";

//=============== Auth ====================
export const userRegisterApi=baseUrl+"/user-register"
export const userLoginApi=baseUrl+"/user-login"
export const userDetailApi=baseUrl+"/user-detail"
export const getAdminReferralApi=baseUrl+"/get-admin-referral"

//================= Package ================
export const getPackageApi=baseUrl +"/get-package"

//================ Stake ============
export const addStakeApi=baseUrl+"/add-stake"
export const addUserUnStakeApi=baseUrl+"/add-user-unstake"
export const getStakeApi=baseUrl+"/get-user-stake"

//================= Dashboard =============
export const getUserDashboardApi=baseUrl+"/get-user-dashboard"

//================= Report =================
export const getRoiReportApi=baseUrl+"/get-user-roi-report"
export const getReferralReportApi=baseUrl+"/get-user-referral-report"
export const getWithDrawReportApi=baseUrl+"/get-user-withdraw-report"

//==================== Withdraw ===============
export const addWithDrawRequestapi=baseUrl+"/add-withdraw-request"
export const withdrawRejectApi=baseUrl+"/user-withdraw-reject"