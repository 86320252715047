import { addWithDrawRequestapi, withdrawRejectApi } from "../constant/api";
import * as opsService from "./Ops";

export const addWithdrawRequest = async (data) => {
    const token =localStorage.getItem("token")
    let result = await opsService.postdata(addWithDrawRequestapi, data,token);
    return result;
  };
 
export const withdarwReject = async (data) => {
    const token =localStorage.getItem("token")
    let result = await opsService.postdata(withdrawRejectApi, data,token);
    return result;
  };
    