import React, { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import Header from "../../widgets/Header";
import { getWithdrawReport } from "../../services/report";
import { useNavigate } from "react-router-dom";
import { transStatus } from "../../constant/enum";
import { withdarwReject } from "../../services/withdraw";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from 'react-toastify';

function WithdrawReport() {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const [withdrawData, setWithdrawData] = useState([]);
  const [disable, setdisable] = useState(false)

  useEffect(() => {
    if (authenticated) {
      getWithdrawData();
    }
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);

  const getWithdrawData = async () => {
    const result = await getWithdrawReport();
    if (result.status) {
      setWithdrawData(result.data);
    } else {
      setWithdrawData([]);
    }
  };

  const withdarwRejectFun=async(id)=>{
    const data={
      id:id
    }
    const result = await withdarwReject(data)
    if (result.status) {
      getWithdrawData()
      toast.dismiss()
      toast.success(result.message);
      setdisable(false)
    }
    else{
      toast.dismiss()
      toast.error(result.message);
      setdisable(false)
    }
  }

  const withdrawAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => withdarwRejectFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "amount",
      text: "Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        const status = record.trans_status;
        return <span>{transStatus[status]}</span>;
      },
    },
    {
      key: "createdAt",
      text: " Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary ms-3"
              disabled={record.trans_status=='2' || record.trans_status=='3'?true:disable}
              onClick={() => {
                withdrawAlert(record.id);
              }}
            >
              <span>Cancel</span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div className="">
      <main className="s-home s-home--static">
        <div className="home-content dash-content">
          <div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12">
                <Header />
                <div className="wallet-connet-tabs container mt-2">
                  <h3>Withdraw List</h3>
                  <div>
                    <ReactDatatable
                      config={config}
                      records={withdrawData}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default WithdrawReport;
