import React, { useState, useEffect } from "react";
import Header from "../widgets/Header";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { bnbChainId, bnbRpcUrl, projectId } from "../constant/enum";
import { userLogin } from "../services/Login";
import { toast } from "react-toastify";

function Login() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const { authenticated, login, setWallet_address } = useAuth();
  const [walletAddress, setWalletAddress] = useState("");
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (address && isConnected) {
      setWalletAddress(address);
    }
  }, [address, isConnected]);
  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };

  const modal=createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    if(!isConnected){
      modal.open()
    }
  }, [isConnected])

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(!walletAddress){
      toast.dismiss();
      toast.error("Please connect wallet address");
      return
    }
    let loginData = {
      wallet_address: walletAddress,
    };
    let result = await userLogin(loginData);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("token", token);
      login();
      toast.success(result.message);
      setWallet_address(address)
      localStorage.setItem("wallet_address",walletAddress)
      setTimeout(function () {
        navigate("/dashboard", { replace: true });
        setDisable(false)
      }, 2000);
     
      return false;
    } else {
      setDisable(false)
      toast.dismiss();
      toast.error(result.message);
      return;
    }
  };

  return (
    <div className=""> 
     {/* <Header />  */}
        <div className="login-form d-flex align-items-center">
        <div className="container">
        <div className="login-form-title text-center">
          <a href="index.html">
          <figure className="login-page-logo">
             <img src="assets/images/login-page-logo.png" alt=""/>
          </figure>
          </a>
          <h2>Welcome back!</h2>
       </div>
         
            <div className="login-form-box">
              <div className="login-card"> 
                  <div className="wallet-register">
                    <h3>Login</h3>
                    <div className="copy-add form-group">
                      <form>
                        <div>
                          <label className="form-label">Wallet Address</label>
                          <input
                            className="input-field form-control"
                            name="walletAddreses"
                            value={walletAddress}
                            disabled
                          />
                        </div>
                      </form>
                    </div>
                 
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleSubmit}
                        disabled={disable}
                      >
                        Login
                      </button>
                       
                       <p>
                       Don't have an Account? Let's <Link className="forgot-password" to="/register">Sign Up</Link>
                       </p>
                    
                  </div> 
              </div>
            </div>
          </div>
        </div> 
    </div>
  );
}

export default Login;
