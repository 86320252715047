import { getUserDashboardApi } from "../constant/api";
import * as opsService from "./Ops"


export const userDashboard = async () => {
    const token =localStorage.getItem("token")
  let result = await opsService.getData(getUserDashboardApi,token);
  return result;
};

