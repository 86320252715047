import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { getPackage } from "../../services/package";
import Header from "../../widgets/Header";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, ethers, parseUnits } from "ethers";
import {
  contractAbi,
  contractAddress,
  decimal,
  stakeActive,
  tokenAbi,
  tokenAddress,
} from "../../constant/enum";
import { toast } from "react-toastify";
import { addStake } from "../../services/stake";
import { userDashboard } from "../../services/dashboard";
import WithdrawRequestModal from "../../partials/WithdrawRequestModal";
import { userDetail } from "../../services/Login";
import { referralLink } from "../../constant/BaseUrl";
import copy from "copy-to-clipboard";
import { getStake } from "../../services/stake";

import StakeRecord from "./StakeRecord";

function Dashboard() {
  const navigate = useNavigate();
  const { authenticated, wallet_address } = useAuth();
  const { isConnected } = useWeb3ModalAccount();
  const [packageData, setPackageData] = useState([]);
  const [activePackage, setActivePackage] = useState("");
  const [approveAmountStatus, setApproveAmountStatus] = useState(false);
  const [packageValidAmount, setPackageValidAmount] = useState({});
  const [amount, setAmount] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [disable, setDisable] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [stakeData, setStakeData] = useState([]);
  const [totalRoi, setTotalRoi] = useState(0)
  const [dailyRoi, setDailyRoi] = useState(0)

  const copyToClipboard = () => {
    if (userData?.wallet_address) {
      toast.success("Referral link copied successfully");
      copy(referralLink + userData?.wallet_address);
    }
  };

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    if (authenticated) {
      getPackageData();
      getDashboard();
      getUserDetail();
      getStakeData();
    }
  }, [authenticated]);

  const getStakeData = async () => {
    const result = await getStake();
    if (result.status) {
      const getFilter=result.data.filter((list)=>{return list.status==stakeActive})
      setStakeData(getFilter);
    } else {
      setStakeData([]);
    }
  };

  const getUserDetail = async () => {
    const result = await userDetail();
    if (result.status) {
      setUserData(result.data[0]);
    } else {
      setUserData({});
    }
  };

  const getDashboard = async () => {
    const result = await userDashboard();
    if (result.status) {
      setDashboardData(result.data);
    }
  };

  const getPackageData = async () => {
    const result = await getPackage();
    if (result.status) {
      setPackageData(result.data);
      setActivePackage(result.data[0].package_name);
      const filter = result.data.filter((list) => {
        return list.package_name == result.data[0].package_name;
      });
      setPackageValidAmount(filter[0]);
      setAmount(filter[0]?.valid_min_amount);
      const roiAmount=(filter[0]?.valid_min_amount*filter[0]?.roi_percentage)/100
      const total_roi=roiAmount* filter[0]?.duration
      setTotalRoi(total_roi)
      setDailyRoi(roiAmount)
    } else {
      setPackageData([]);
    }
  };

  const handleActivePackage = (e) => {
    setActivePackage(e.target.value);
    const filter = packageData.filter((list) => {
      return list.package_name == e.target.value;
    });
    setPackageValidAmount(filter[0]);
    setAmount(filter[0].valid_min_amount);
    const roiAmount=(filter[0]?.valid_min_amount*filter[0]?.roi_percentage)/100
    const total_roi=roiAmount* filter[0]?.duration
    setTotalRoi(total_roi)
    setDailyRoi(roiAmount)
  };

  useEffect(() => {
    if(amount && wallet_address){
      approvalCheck()
    }
  }, [isConnected,amount,activePackage])

  const approvalCheck=async ()=>{
    const checkA = await checkApproval();
    if (amount > checkA) {
      setApproveAmountStatus(true);
    } else {
      setApproveAmountStatus(false);
    }
  }
  

  const checkApproval = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const provider = await getSignerOrProvider();
        const contract = new Contract(tokenAddress, tokenAbi, provider);
        let checkA = await contract.allowance(wallet_address, contractAddress);
        checkA = parseFloat(checkA);
        checkA = checkA / 10 ** decimal;
        resolve(checkA);
      } catch (error) {
        console.log(error)
      }
     
    });
  };

  const handleAmount = async (e) => {
    const { value } = e.target;
    const checkA = await checkApproval();
    if (packageValidAmount?.valid_max_amount >= value) {
      setAmount(value);
    }
    if (value > checkA) {
      setApproveAmountStatus(true);
    } else {
      setApproveAmountStatus(false);
    }
  };

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const approveAmount = async () => {
    const provider = await getSignerOrProvider();
    const contract = new Contract(tokenAddress, tokenAbi, provider);

    let transaction = await contract.approve(
      contractAddress,
      "99999999990000000000000000000000"
    );
    toast.success("Waiting for confirmation");
    await transaction.wait();
    setTimeout(() => {
      setApproveAmountStatus(false);
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const checkA = await checkApproval();
      console.log(checkA,"checkA")
      if (amount > checkA) {
        setApproveAmountStatus(true);
        return false
      } else {
        setApproveAmountStatus(false);
      }
      if (!amount) {
        toast.error("Please enter valid amount");
        return false;
      }
      if (parseFloat(amount) <= 0) {
        toast.error("Please enter valid amount");
        return false;
      }
      setDisable(true);
      const filter = packageData.filter((list) => {
        return list.package_name == activePackage;
      });
      
      if (
        filter[0].valid_min_amount <= parseFloat(amount) &&
        filter[0].valid_max_amount >= parseFloat(amount)
      ) {
        const provider = await getSignerOrProvider();
        const contract = new Contract(contractAddress, contractAbi, provider);
        let buyAmount =parseUnits(amount.toString())
        let tx = await contract.deposit(buyAmount.toString());
        toast.dismiss();
        toast.success("Your package buy successfully please wait a second");
        await tx.wait();

        if (tx) {
          const sendData = {
            amount: amount,
            package_name: activePackage,
            trans_hash: tx.hash,
          };
          let result = await addStake(sendData);
          if (result.status) {
            getDashboard();
            toast.dismiss();
            toast.success(result.message);
          } else {
            toast.dismiss();
            toast.error(result.message);
          }
        }
        setTimeout(() => {
          setDisable(false);
          setAmount(filter[0].valid_min_amount);
          getStakeData()
        }, 10000);
      } else {
        setDisable(false);
        toast.error(
          `Please enter more than ${filter[0].valid_min_amount} and less than ${filter[0].valid_max_amount} amount`
        );
        return false;
      }
    } catch (error) {
      console.log(error)
      toast.error(error.reason);
      setDisable(false);
    }
  };
 
  return (
    <div className=" ">
      <Header />

      <div className="pt-4  fos pay_dashbord pb-5">
        <div className="container mt-1">
          <div className="wallet-connet-tabs">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="card_box p-3">
                  <h5 className="fw600">Referral LInk</h5>
                  <div className="d-flex mb-1  align-items-center">
                   {stakeData.length==0?<p>Referral link generate after buy package</p>:<><span className="word-br">
                      {referralLink +
                        `${
                          userData?.wallet_address &&
                          userData?.wallet_address.substring(0, 7)
                        }...${
                          userData?.wallet_address &&
                          userData?.wallet_address.substring(
                            wallet_address.length - 7
                          )
                        }`}
                    </span>
                    <button
                      className="btn btn_d ml-auto"
                      onClick={() => {
                        copyToClipboard();
                      }}
                    >
                      Copy
                    </button>
                    </> }
                  </div>

                  <div className="d-flex mb-1">
                    <span className="word-br">Sponsor ID : {userData?.sponser_wallet_address}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="card_box p-3">
                  <div className="row">
                    <div className="mb-2 col-md-6">
                      <label className="form-label">AI Arbitrage Package</label>
                      <select
                        value={activePackage}
                        onChange={handleActivePackage}
                        className="form-control mb-2 cap"
                      >
                        {packageData?.map((list) => {
                          return (
                            <option value={list.package_name} className="cap">
                              {list.package_name} ({list.months} Months)
                            </option>
                          );
                        })}
                      </select>
                      <p className="m-0"><b>Estimated Return: {totalRoi} USDT</b></p>
                      <p><b>Daily Return: {dailyRoi} USDT</b></p>
                    </div>
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Amount (USDT) ({packageValidAmount?.valid_min_amount})
                      </label>
                      <input
                        placeholder="Enter Amount"
                        className="form-control"
                        onChange={handleAmount}
                        value={amount}
                        onKeyPress={(event) => {
                          const inputValue = event.target.value;

                          // Allow only numbers and one decimal point
                          if (!/[0-9]/.test(event.key) && event.key !== ".") {
                            event.preventDefault();
                          }

                          // Prevent entering more than one decimal point
                          if (event.key === "." && inputValue.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                  <div className="mr-auto">
                  <img className="coins h40" src="./assets/images/coins.png"  alt="" />
                  </div>
                  <div className="">
                    {approveAmountStatus ? (
                      <button className="btn btn_d" onClick={approveAmount}>
                        Approve
                      </button>
                    ) : (
                      <button
                        className="btn btn_d"
                        onClick={handleSubmit}
                        disabled={disable}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div className="row row5 text-center">
              <div className="col-md-4 box-des  mb-4 ">
                <div className="card_box p-3">
                  <i class="fa-solid fa-dollar-sign d_icon"></i>
                  <i class="fa-solid fa-dollar-sign d_icon2"></i>
                  <label>Total Income</label>
                  <h4 className="fw600">USDT {dashboardData?.total_income}</h4>
                </div>
              </div>
              <div className="col-md-4 box-des  mb-4">
                <div className="card_box p-3">
                  <i class="fa-solid fa-cubes d_icon"></i>
                  <i class="fa-solid fa-cubes d_icon2"></i>
                  <label>Total Stake</label>
                  <h4 className="fw600">USDT {dashboardData?.total_stake}</h4>
                </div>
              </div>
              <div className="col-md-4 box-des  mb-4">
                <div className="card_box p-3">
                  <i class="fa-solid fa-money-bill-trend-up d_icon"></i>
                  <i class="fa-solid fa-money-bill-trend-up d_icon2"></i>
                  <label>Total ROI</label>
                  <h4 className="fw600">USDT {dashboardData?.total_roi}</h4>
                </div>
              </div>
              <div className="col-md-4 box-des  mb-4">
                <div className="card_box p-3">
                  <i class="fa-regular fa-share-from-square d_icon"></i>
                  <i class="fa-regular fa-share-from-square d_icon2"></i>
                  <label>Total Referral</label>
                  <h4 className="fw600">USDT {dashboardData?.total_referral}</h4>
                </div>
              </div>
              <div className="col-md-4 box-des  mb-4">
                <div className="card_box p-3">
                  <i class="fa-solid fa-building-columns d_icon"></i>
                  <i class="fa-solid fa-building-columns d_icon2"></i>
                  <label>Available Balance</label>
                  <h4 className="fw600">
                  USDT {dashboardData?.available_balance}
                  </h4>
                  <div className="">
                    <button
                      className="btn btn_d2 btn_d_small"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-6 mt-4">
            <div className="card_box p-3">
              <h5>Disclaimer</h5>
              <ul className="list pl-4">
              <li className="mb-2">  Daily income is sent to your Bit Pay Earn wallet (USDT wallet).</li>
              <li className="mb-2"> Zero risk of your investment funds you can get your funds back after ending packages.</li>
              <li className="mb-2"> You can invest and withdraw only in USDT (BEP-20)</li>
              <li className="mb-2">Crypto currency investments are subject to market risks</li>
              
              </ul>
            </div>
           
          </div>
          <div className="col-md-6 mt-4">
            <div className="card_box p-3">
              <h5>Terms & Condition</h5>
              <ul className="list pl-4">
              <li className="mb-2">You can Withdraw one time in 24 hours </li>
              <li className="mb-2">You can unstake your package in 25 days only</li>
              <li className="mb-2"> On unstake 35% Fee Will be deducted and collected ROI will be deducted</li>
              <li className="mb-2">Withdrawal amount will be receive in 24 hours</li>
              <li className="mb-2">Minimum withdraw amount is $5 and withdraw fee 10% is applicable.</li>
              
              </ul>
            </div>
           
          </div>
        
          </div>
          </div>
          <div className="card_box p-3 mt-4">
              <StakeRecord stakeData={stakeData} getStakeData={getStakeData} getDashboard={getDashboard}/>
            </div>
        </div>
      </div>
      <WithdrawRequestModal
        setShowModal={setShowModal}
        showModal={showModal}
        available_balance={dashboardData?.available_balance}
        getDashboard={getDashboard}
      />
    </div>
  );
}

export default Dashboard;
