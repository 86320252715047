import React,{useEffect,useState} from 'react'
import { useAuth } from '../../../AuthContext';
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import Header from '../../widgets/Header';
import { getReferralReport } from '../../services/report';
import { useNavigate } from 'react-router-dom';

function ReferralReport() {
    const { authenticated } = useAuth();
    const navigate=useNavigate()
   const [referralData, setReferralData] = useState([])

   useEffect(() => {
    if(authenticated){
        getReferralData()
    }
   }, [authenticated])

   useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);
   
   const getReferralData=async()=>{
    const result=await getReferralReport()
    if(result.status){
        setReferralData(result.data)
    }
    else{
        setReferralData([])
    }
   }

   const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
        key: "sponser",
        text: "Sponser Wallet Address",
        className: "email",
        align: "left",
        sortable: true,
      },
    {
      key: "amount",
      text: "Roi Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: " Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },


  
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div className="">
    <main className="s-home s-home--static">
      <div className="home-content dash-content">
        <div >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <Header />
              <div className="wallet-connet-tabs container mt-2">
               <h3>Referral List</h3>
               <div>
               <ReactDatatable
                config={config}
                records={referralData}
                columns={columns}
              />
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  )
}

export default ReferralReport