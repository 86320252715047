import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from './components/pages/home/Home';
import Login from './components/auth/Login';
import Register from "./components/auth/Register";
import Dashboard from "./components/pages/dashboard/Dashboard";
import StakeRecord from "./components/pages/stake/StakeRecord";
import RoiReport from "./components/pages/report/RoiReport";
import ReferralReport from "./components/pages/report/ReferralReport";
import WithdrawReport from "./components/pages/report/WithdrawReport";

function App() {
  return (
   <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          <Route path="/register/:referralCode" element={<Register />}/>
          <Route path="/dashboard" element={<Dashboard />}/>
          <Route path="/stake-record" element={<StakeRecord />}/>
          <Route path="/roi-record" element={<RoiReport />}/>
          <Route path="/referral-record" element={<ReferralReport />}/>
          <Route path="/withdraw-record" element={<WithdrawReport />}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
   </>
  );
}

export default App;
